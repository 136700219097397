<script>
export default {
    name: "PageHeader",
    props: {
        links: {
            type: Array,
            default: () => {}
        },
        logoLink: {
            type: Boolean,
            default: false
        },
        showLoginButton: {
            type: Boolean,
            default: true
        },
    },
    data() {
        return {
            mobileNavigationBg: false,
        };
    },
    methods: {
        scrollToBlock(blockName) {
            event.preventDefault();
            if (blockName) {
                const item = document.getElementById(blockName);
                window.scrollTo({
                    top: item.offsetTop - 70,
                    left: 0,
                    behavior: 'smooth'
                });
            } else {
                window.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: 'smooth'
                });
            }
        },
    }
}
</script>

<template>
    <div class="row">
        <div class="col p-0">
            <div class="header px-32 d-flex justify-content-between w-100" :class="{ 'mobile-menu-active': mobileNavigationBg }">
                <div class="left-col d-flex w-100 align-items-center">
                    <div class="mobile-navigation">
                        <nav role="navigation">
                            <div class="menu-toggle">
                                <input type="checkbox" v-model="mobileNavigationBg"/>
                                <span></span>
                                <span></span>
                                <span></span>
                                <ul class="mobile-menu">
                                    <li
                                        v-for="(link, i) in links"
                                        :key="i"
                                    >
                                        <a
                                            v-if="link.external"
                                            :href="link.href"
                                        >
                                            {{link.text}}
                                        </a>
                                        <a
                                            v-else
                                            href="#"
                                            @click="scrollToBlock(link.scrollBlock)"
                                        >
                                            {{link.text}}
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </nav>
                    </div>
                    <div class="logo-block d-flex align-items-center">
                        <a
                            v-if="logoLink"
                            href="/"
                            class="logo"
                        ></a>
                        <a
                            v-else
                            href="#"
                            class="logo"
                            @click="scrollToBlock(null)"
                        ></a>
                    </div>
                    <div class="desktop-navigation">
                        <nav class="navigation">
                            <template
                                v-for="link in links"
                            >
                                <a
                                    v-if="link.external"
                                    :href="link.href"
                                >
                                    {{link.text}}
                                </a>
                                <a
                                    v-else
                                    href="#"
                                    @click="scrollToBlock(link.scrollBlock)"
                                >
                                    {{link.text}}
                                </a>
                            </template>
                        </nav>
                    </div>
                </div>
                <div
                    v-if="showLoginButton"
                    class="right-col d-flex align-items-center justify-content-end"
                >
                    <a class="btn" href="https://system.geoeffect.ru/">Вход</a>
                </div>
            </div>
            <div v-if="mobileNavigationBg" class="mobile-navigation-bg"></div>
        </div>
    </div>
</template>

<style lang="scss">
// noinspection CssUnknownTarget
@import '@design/components/header.scss';
</style>
