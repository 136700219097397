<script>
import Layout from "@layouts/main.vue";
import PageHeader from "@components/page-header.vue";

export default {
    name: "Error404",
    components: {
        Layout,
        PageHeader,
    },
    data() {
        return {
            headerLinks: [
                {
                    text: 'Поддержка',
                    external: true,
                    href: 'https://support.mts.ru/mts-tsmr',
                    scrollBlock: null
                }
            ]
        }
    },
    computed: {
        currentYear() {
            return new Date().getFullYear();
        }
    }
}
</script>

<template>
    <Layout class="landing-page">
        <PageHeader
            :links="headerLinks"
            :logo-link="true"
            :show-login-button="false"
        />
        <div class="error-wrapper">
            <div class="error-info">
                <div class="error-title">Страница не найдена</div>
                <div class="error-text">Но вы можете <a href="/" class="error-link">вернуться назад</a></div>
            </div>
            <div class="error-img error-img-404"></div>
            <div class="error-copyright">© {{ currentYear }}    18+ </div>
        </div>
    </Layout>
</template>

<style lang="scss">
@import '@design/error404.scss';
</style>